import React from "react";
import Style from "../css/Index.module.css";
function SkillsCard({ icon, title, iconColor, size }) {
  return (
    <>
      <div
        className={`  mt-2 ${Style.skillsCard}`}
        style={{ "--iconColor": iconColor }}
      >
        <span>{icon}</span>
        <section style={{ "--size": size ? size : "35px" }}>{title}</section>
      </div>
    </>
  );
}

export default SkillsCard;
