function DragImg({ drag, src, id, alt }) {
  return (
    <img
      src={src}
      draggable="true"
      onDragStart={(e) => drag(e)}
      id={`img${id}`}
      alt={alt}
    />
  );
}

export default DragImg;
