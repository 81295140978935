import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./project/screens/Index";
import "./project/css/important.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { useEffect } from "react";
function App() {
  document.documentElement.style.setProperty(
    "--text-orange",
    localStorage.getItem("text1")
  ); //white
  document.documentElement.style.setProperty(
    "--text-coffee",
    localStorage.getItem("text2")
  ); //black
  document.documentElement.style.setProperty(
    "text-dark",
    localStorage.getItem("text1")
  ); //white
  document.documentElement.style.setProperty(
    "--text-white",
    localStorage.getItem("text3")
  ); //third color

  document.documentElement.style.setProperty(
    "--text-black",
    localStorage.getItem("text1")
  ); //white

  document.documentElement.style.setProperty(
    "--bg-orange",
    localStorage.getItem("bg1")
  ); //white
  document.documentElement.style.setProperty(
    "--bg-coffee",
    localStorage.getItem("bg2")
  ); //black

  // useEffect(() => {

  // }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
