import Style from "../css/Index.module.css";
export function Experience() {
  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="card border-0 bg-transparent">
            <div
              className={`card-body border rounded h4 ${Style.experienceCard}`}
            >
              <h3 className={`${Style.title}`}>Virtue Mantra</h3>
              <p>
                In <span className="text-white">February 2020</span>, I
                kickstarted my career journey at Virtue Mantra as a backend
                developer.
              </p>
              <p>
                Working at Virtue Mantra was an exceptional learning experience
                where I acquired several skills and knowledge in PHP language,
                teamwork, and other areas of software development. Virtue Mantra
                was the first company I worked for, and it was a valuable
                stepping stone in my career journey.
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-6">
          <div className="card border-0 bg-transparent">
            <div
              className={`card-body border rounded h4 ${Style.experienceCard}`}
            >
              <h3 className={`${Style.title}`}>Insurancelogy</h3>
              <p>
                From
                <span className="text-white mx-1">
                  December 2020 to September 2022
                </span>
                , I worked as a Frontend Developer at Insurancelogy, where I
                honed my skills as both a{" "}
                <span className="text-white">
                  React Js and Laravel developer
                </span>
                . It was an excellent opportunity for me to improve my technical
                skills and collaborate with other developers.
              </p>
              <p>
                I'm grateful for the experience I gained at Insurancelogy, and
                I'm excited to utilize the skills I learned in future endeavors.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
