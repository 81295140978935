import Style from "../css/Index.module.css";

function StepperItem({ icon, text, height }) {
  return (
    <div className={`${Style.bubble} ${Style.contentCenter}`}>
      {icon}
      <div className={Style.hoverWrapper}>
        <div className={`${Style.bubbleHover} ${Style.contentCenter}`}>
          {text}
        </div>
      </div>
    </div>
  );
}

export default StepperItem;
