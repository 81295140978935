import React from "react";
import EduCard from "./EduCard";
import { VscLaw } from "react-icons/vsc";
import { SlGraduation } from "react-icons/sl";
import { TbCertificate2 } from "react-icons/tb";
import { TbCertificate } from "react-icons/tb";

function Education() {
  return (
    <div className="container-fluid mb-5">
      <div className="row">
        {/*  First Card */}
        <div className="col-11 col-sm-6 col-md-5 col-lg-4 col-xl-3 my-2  mx-auto">
          <EduCard
            title="LLB"
            color="var(--bg-blue)"
            college={
              <>
                <span>SRT Campus Badshahi Thaul</span>
                <br />
                <span>Tehri</span>
              </>
            }
            year="20013 - 2018"
            icon={<VscLaw />}
            body={
              <div className=" text-center mt-2">
                <div>
                  I completed my
                  <span className="text-primary mx-1 fw-bold">
                    Bachelor In Legislative Law (LLB)
                  </span>
                  in 2018.
                </div>
              </div>
            }
          />
        </div>

        {/*  Second Card */}
        <div className="col-11 col-sm-6 col-md-5 col-lg-4 col-xl-3 my-2  mx-auto">
          <EduCard
            title="Graduation"
            color="#01b4bc"
            college={
              <>
                <span>PG College</span>
                <br />
                <span>Rishikesh</span>
              </>
            }
            year="2009 - 2012"
            icon={<SlGraduation />}
            body={
              <div className=" text-center mt-2">
                <div>
                  I completed my
                  <span className="text-primary text-capitalize fw-bold mx-1">
                    Graduation (Bachelor in commerce)
                  </span>
                  in 2012.
                </div>
              </div>
            }
          />
        </div>

        {/*  third Card */}
        <div className="col-11 col-sm-6 col-md-5 col-lg-4 col-xl-3 my-2  mx-auto">
          <EduCard
            title="Intermediate"
            color="#5fa55a"
            college={
              <>
                <span>PSK Inter College</span>
                <br />
                <span>Rishikesh</span>
              </>
            }
            year="2006 - 2008"
            icon={<TbCertificate2 />}
            body={
              <div className=" text-center mt-2">
                <div>
                  I completed my
                  <span className="text-primary text-capitalize fw-bold mx-1">
                    12<sup>th</sup>
                  </span>
                  in 2008 from Uttarakhand Board.
                </div>
              </div>
            }
          />
        </div>

        {/*  Fourth Card */}
        <div className="col-11 col-sm-6 col-md-5 col-lg-4 col-xl-3 my-2  mx-auto">
          <EduCard
            title="High School"
            color="#5f5aa5cc"
            college={
              <>
                <span>PSK Inter College</span>
                <br />
                <span>Rishikesh</span>
              </>
            }
            year="2005 - 2006"
            icon={<TbCertificate />}
            body={
              <div className=" text-center mt-2">
                <div>
                  I completed my
                  <span className="text-primary text-capitalize fw-bold mx-1">
                    10<sup>th</sup>
                  </span>
                  in 2006 from UK Board.
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Education;
