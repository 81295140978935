import { useState } from "react";
import Style from "../css/Puzzel.module.css";
import DragImg from "./DragImg";
export function Puzzle() {
  const [dragItem, setDragItem] = useState({
    img1: "",
    img2: "",
    img3: "",
    img4: "",
    img5: "",
    img6: "",
    img7: "",
    img8: "",
    img9: "",
  });

  const [status, setStatus] = useState(0);

  function allowDrop(ev) {
    ev.preventDefault();
  }

  function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  }

  function drop(ev) {
    ev.preventDefault();
    // console.log(ev.dataTransfer);
    var data = ev.dataTransfer.getData("text");
    if (ev.target.nodeName !== "IMG") {
      ev.target.appendChild(document.getElementById(data));
      const contId = ev.target.id;
      const imgId = ev.target.children[0].id;
      setDragItem({ ...dragItem, [imgId]: Number(contId) });
    }
  }
  if (
    dragItem.img1 === 9 &&
    dragItem.img2 === 8 &&
    dragItem.img3 === 7 &&
    dragItem.img4 === 6 &&
    dragItem.img5 === 5 &&
    dragItem.img6 === 4 &&
    dragItem.img7 === 3 &&
    dragItem.img8 === 2 &&
    dragItem.img9 === 1 &&
    document.getElementById("piecesContainer").childNodes.length === 0 &&
    status === 0
  ) {
    let border = document.querySelector("table");
    border.className = "";
    setStatus(1);
  }

  // console.log(" he " + window.navigator.userAgent);
  return (
    <div
      className={`container-fluid mt-70 d-none d-md-block`}
      data-puzzel="puzzel"
    >
      <div className="row">
        <div className="col-12">
          <div className={`${Style.textNavy} h2 text-center`}>
            Getting Bore?
          </div>
        </div>
      </div>
      <div className="row">
        {status === 1 ? (
          <>
            <div className="col-12 h1 text-success text-center">
              Hurray!! Cleared successfuly!
            </div>
          </>
        ) : (
          ""
        )}

        <div className="col-12 col-md-6 col-lg-6 mx-auo my-2">
          <div
            className={`${Style.tableContainer} d-flex justify-content-center align-items-center w-100`}
          >
            <table className={Style.table}>
              <tbody>
                <tr>
                  <td
                    id="1"
                    onDrop={(e) => drop(e)}
                    onDragOver={(e) => allowDrop(e)}
                  ></td>
                  <td
                    id="2"
                    onDrop={(e) => drop(e)}
                    onDragOver={(e) => allowDrop(e)}
                  ></td>
                  <td
                    id="3"
                    onDrop={(e) => drop(e)}
                    onDragOver={(e) => allowDrop(e)}
                  ></td>
                </tr>
                <tr>
                  <td
                    id="4"
                    onDrop={(e) => drop(e)}
                    onDragOver={(e) => allowDrop(e)}
                  ></td>
                  <td
                    id="5"
                    onDrop={(e) => drop(e)}
                    onDragOver={(e) => allowDrop(e)}
                  ></td>
                  <td
                    id="6"
                    onDrop={(e) => drop(e)}
                    onDragOver={(e) => allowDrop(e)}
                  ></td>
                </tr>
                <tr>
                  <td
                    id="7"
                    onDrop={(e) => drop(e)}
                    onDragOver={(e) => allowDrop(e)}
                  ></td>
                  <td
                    id="8"
                    onDrop={(e) => drop(e)}
                    onDragOver={(e) => allowDrop(e)}
                  ></td>
                  <td
                    id="9"
                    onDrop={(e) => drop(e)}
                    onDragOver={(e) => allowDrop(e)}
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6 mx-auto d-flex justify-content-center align-items-center my-2">
          <div
            className={`${Style.piecesContainer}`}
            onDrop={(e) => drop(e)}
            id="piecesContainer"
            onDragOver={(e) => allowDrop(e)}
          >
            <DragImg
              src="./assets/images/1.jpg"
              drag={(e) => drag(e)}
              id="1"
              alt="Shivam Mittal"
            />

            <DragImg
              src="./assets/images/2.jpg"
              drag={(e) => drag(e)}
              id="2"
              alt="Shivam Mittal"
            />

            <DragImg
              src="./assets/images/3.jpg"
              drag={(e) => drag(e)}
              id="3"
              alt="Shivam Mittal"
            />

            <DragImg
              src="./assets/images/4.jpg"
              drag={(e) => drag(e)}
              id="4"
              alt="Shivam Mittal"
            />

            <DragImg
              src="./assets/images/5.jpg"
              drag={(e) => drag(e)}
              id="5"
              alt="Shivam Mittal"
            />

            <DragImg
              src="./assets/images/6.jpg"
              drag={(e) => drag(e)}
              id="6"
              alt="Shivam Mittal"
            />

            <DragImg
              src="./assets/images/7.jpg"
              drag={(e) => drag(e)}
              id="7"
              alt="Shivam Mittal"
            />

            <DragImg
              src="./assets/images/8.jpg"
              drag={(e) => drag(e)}
              id="8"
              alt="Shivam Mittal"
            />

            <DragImg
              src="./assets/images/9.jpg"
              drag={(e) => drag(e)}
              id="9"
              alt="Shivam Mittal"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
