import Style from "../css/Index.module.css";
import { CiMobile1 } from "react-icons/ci";
import { SiWhatsapp } from "react-icons/si";
import { MdAlternateEmail } from "react-icons/md";
import { AiOutlineLinkedin } from "react-icons/ai";
import { Link } from "react-router-dom";

function ContactMe() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className={`col-12 ${Style.contactUs} position-relative py-5 px-0`}
        >
          <iFrame src="https://maps.google.com/maps?q=bankhandi%20ramlina%20ground%20rishikesh&t=&z=13&ie=UTF8&iwloc=&output=embed"></iFrame>

          <div className={Style.iFrameLayer}></div>

          <div className={` ${Style.contactUsForm} d-flex flex-column gap-3`}>
            <div
              className="d-flex gap-3 justify-content-start align-items-center"
              title="This number also available on whatsapp"
            >
              <CiMobile1
                size={70}
                className={` rounded-circle p-2 ${Style.icon}`}
              />
              <div>
                <div className="small text-start"> Mobile </div>
                <Link
                  to="tel:+919997077644"
                  className={`text-decoration-none my-auto h5 ${Style.a}`}
                >
                  9997077644
                </Link>
              </div>
            </div>
            {/* whatsapp */}
            <div className="d-flex gap-3 justify-content-start align-items-center">
              <SiWhatsapp
                size={60}
                className={` rounded-circle p-2 ${Style.icon}`}
              />

              <div>
                <div className="small text-start"> Whatsapp </div>
                <Link
                  to="https://wa.me/9997077644?text=Hello Shivam, Are you available?"
                  className={`text-decoration-none my-auto h5 ${Style.a}`}
                  target="_blank"
                >
                  9997077644
                </Link>
              </div>
            </div>
            {/* email */}
            <div className="d-flex flex-column flex-sm-row flex-wrap gap-3 justify-content-start align-items-center">
              <MdAlternateEmail
                size={60}
                className={`rounded-circle p-2 ${Style.icon}`}
              />

              <div>
                <div className="small text-start"> Email </div>
                <Link
                  to="mailto:thedevelopershivam@gmail.com"
                  className={`my-auto h5 text-decoration-none ${Style.a}`}
                  target="_blank"
                >
                  thedevelopershivam@gmail.com
                </Link>
              </div>
            </div>

            <div className="d-flex gap-3 justify-content-start align-items-center">
              <AiOutlineLinkedin
                size={60}
                className={` rounded-circle p-2 ${Style.icon}`}
              />

              <div>
                <div className="small text-start"> Linkedin </div>
                <Link
                  to="https://linkedin.com/in/shivam-mittal-21578821b"
                  className={`my-auto h5 text-decoration-none ${Style.a} `}
                  target="_blank"
                >
                  Linkedin Profile
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactMe;
