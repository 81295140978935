import React from "react";
import Style from "../css/Index.module.css";
import SkillsCard from "./SkillsCard";
import {
  SiHtml5,
  SiCss3,
  SiBootstrap,
  SiReact,
  SiTailwindcss,
  SiPhp,
  SiMysql,
} from "react-icons/si";

function Skills() {
  return (
    <div className={`container-fluid ${Style.skills}`}>
      <div className="row">
        <section className={`col-12 m-auto ${Style.textContent} `}>
          <p>
            As a developer, I firmly believe that custom and unique layouts
            created using a combination of HTML, CSS, Bootstrap, Tailwind CSS,
            React JS, PHP, and SQL can elevate the user experience to new
            heights. By leveraging these technologies, we can create layouts
            that are not only visually stunning, but also highly functional and
            responsive.
          </p>
          <p>
            Custom layouts offer numerous benefits, including the ability to
            create reusable components that can be easily managed and updated.
            With HTML and CSS as our foundation, we can use Bootstrap and
            Tailwind CSS to speed up development and ensure consistency across
            pages. React JS allows for the creation of dynamic and interactive
            user interfaces, while PHP and SQL enable seamless integration of
            back-end functionality and database management.
          </p>
          <p>
            In summary, custom layouts created using these powerful technologies
            offer unparalleled flexibility and efficiency, making them an
            essential tool for developers who want to create exceptional user
            experiences. By embracing custom layouts, we can create designs that
            are not only beautiful but also functional, responsive, and highly
            customizable, setting our projects apart and leaving a lasting
            impression on users.
          </p>
        </section>
        <div className={`${Style.skillsCardWrapper}`}>
          <SkillsCard
            iconColor="#61dafb"
            icon={<SiReact />}
            title="React JS"
            size="30px"
          />
          <SkillsCard
            iconColor="#222"
            icon={<SiHtml5 />}
            title="HTML"
            size="28px"
          />

          <SkillsCard
            iconColor="rgba(100, 100, 200, 1)"
            icon={<SiCss3 />}
            title="CSS"
            size="28px"
          />

          <SkillsCard
            iconColor="#7634f9"
            icon={<SiBootstrap />}
            title="Bootstrap"
            size="26px"
          />

          <SkillsCard
            iconColor="rgb(56, 189, 248)"
            icon={<SiTailwindcss />}
            title="Tailwind Css*"
            size="26px"
          />

          <SkillsCard
            iconColor="#7a86b8"
            icon={<SiPhp />}
            title="PHP"
            size="26px"
          />

          <SkillsCard
            iconColor="#3e6e93"
            icon={<SiMysql />}
            title="MySql"
            size="28px"
          />
        </div>
      </div>
    </div>
  );
}

export default Skills;
