import React from "react";
import { Link } from "react-router-dom";
import { ImArrowRight2 } from "react-icons/im";
import Style from "../css/Index.module.css";

function ProjectCard() {
  return (
    <div className="container-fluid ">
      <div className="row">
        {/* Image card first */}

        <div className="col-11 col-sm-10 col-md-4 mx-auto mb-5">
          <div className="d-flex flex-column gap-3">
            <iFrame
              src={"https://pankajyogpeeth.shopindiazz.link/"}
              title="albuhaira"
            ></iFrame>
            <Link
              to={"https://pankajyogpeeth.shopindiazz.link/"}
              className={`btn shadow ${Style.projectButton}`}
              target="_blank"
            >
              <span>Redirect</span>
              <ImArrowRight2 />
            </Link>
          </div>
        </div>

        {/* Image card second */}

        <div className="col-11 col-sm-10 col-md-4 mx-auto mb-5">
          <div className="d-flex flex-column gap-3">
            <iFrame
              src={"https://www.albuhaira.com"}
              title="albuhaira"
            ></iFrame>
            <Link
              to={"https://www.albuhaira.com"}
              className={`btn shadow ${Style.projectButton}`}
              target="_blank"
            >
              <span>Redirect</span>
              <ImArrowRight2 />
            </Link>
          </div>
        </div>

        {/* Image card third */}

        <div className="col-11 col-sm-10 col-md-4 mx-auto mb-5">
          <div className="d-flex flex-column gap-3">
            <iFrame
              src={"https://www.getcarinsurance.ae"}
              title="albuhaira"
            ></iFrame>
            <Link
              to={"https://www.getcarinsurance.ae"}
              className={`btn shadow ${Style.projectButton}`}
              target="_blank"
            >
              <span>Redirect</span>
              <ImArrowRight2 />
            </Link>
          </div>
        </div>

        {/* Image card fourth */}

        <div className="col-11 col-sm-10 col-md-4 mx-auto mb-5">
          <div className="d-flex flex-column gap-3">
            <iFrame
              src={
                "https://kamalchaudhary.work/vicky/admin_module/dashboard.php"
              }
              title="albuhaira"
            ></iFrame>
            <Link
              to={
                "https://kamalchaudhary.work/vicky/admin_module/dashboard.php"
              }
              className={`btn shadow ${Style.projectButton}`}
              target="_blank"
            >
              <span>Redirect</span>
              <ImArrowRight2 />
            </Link>
          </div>
        </div>

        {/* Image card fifth */}

        <div className="col-11 col-sm-10 col-md-4 mx-auto mb-5">
          <div className="d-flex flex-column gap-3">
            <iFrame
              src={"http://labourwala.sewagroups.com"}
              title="albuhaira"
            ></iFrame>
            <Link
              to={"http://labourwala.sewagroups.com"}
              className={`btn shadow ${Style.projectButton}`}
              target="_blank"
            >
              <span>Redirect</span>
              <ImArrowRight2 />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
