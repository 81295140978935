import React from "react";
import Style from "../css/edu.module.css";

function EduCard({ title, year, body, icon, college, color }) {
  return (
    <div className={`card border-0 ${Style.eduCard}`}>
      <div className={`card-body ${Style.eduCardBody}`}>
        <div
          className={`${Style.titleWrapper} shadow d-flex justify-content-between`}
        >
          <div className={Style.vt} style={{ "--background-navy": color }}>
            {title}
          </div>
          <div className="p-2 d-flex flex-column justify-content-center align-items-center">
            <div style={{ color: "white", letterSpacing: 2 }}>
              <span className={`fw-bold h5 d-block`}>{college}</span>
              <span className={`fw-bold h6`}>{year}</span>
            </div>
            <div>{body}</div>
          </div>
        </div>
        <div
          className={`${Style.iconWrapper} d-flex justify-content-center align-items-center text-white`}
          style={{ "--background-navy": color }}
        >
          {icon}
        </div>
      </div>
    </div>
  );
}

export default EduCard;
