import React, { useState, useEffect } from "react";
import Style from "../css/Index.module.css";

export default function AnimatedText({ animtedText, className }) {
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const words = animtedText;

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isDeleting) {
        if (text.length === words[currentIndex].length) {
          setIsDeleting(true);
        } else {
          setText(
            (prevText) => prevText + words[currentIndex][prevText.length]
          );
        }
      } else {
        if (text.length === 0) {
          setIsDeleting(false);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
        } else {
          setText((prevText) => prevText.slice(0, -1));
        }
      }
    }, 200);
    return () => clearTimeout(timeout);
  }, [text, currentIndex, words, isDeleting]);

  return (
    <div className={`${Style.animatedText} className `}>
      <span className="text-uppercase">
        {currentIndex > 1 ? "I can Work with  " : ""}
      </span>
      {currentIndex > 1 && <br />}
      <span className={`${Style.height80} text-uppercase`}>{text}</span>
    </div>
  );
}
