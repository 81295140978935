import React from "react";
import Style from "../css/Index.module.css";
import { CiUser } from "react-icons/ci";
import { SlGraduation } from "react-icons/sl";
import { SiSkillshare } from "react-icons/si";
import { RxMobile } from "react-icons/rx";
import { FaWhatsapp } from "react-icons/fa";
import { TiContacts } from "react-icons/ti";
import { CiLinkedin } from "react-icons/ci";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlineCog6Tooth } from "react-icons/hi2";

import StepperItem from "./StepperItem";

function Bubbles() {
  const themeHandler = (text1, text2, text3, bg1, bg2) => {
    // console.log(localStorage.clear());
    document.documentElement.style.setProperty(
      "--text-orange",
      localStorage.getItem("text1")
    ); //white
    document.documentElement.style.setProperty(
      "--text-coffee",
      localStorage.getItem("text2")
    ); //black
    document.documentElement.style.setProperty(
      "text-dark",
      localStorage.getItem("text1")
    ); //white
    document.documentElement.style.setProperty(
      "--text-white",
      localStorage.getItem("text3")
    ); //third color

    document.documentElement.style.setProperty(
      "--text-black",
      localStorage.getItem("text1")
    ); //white

    document.documentElement.style.setProperty(
      "--bg-orange",
      localStorage.getItem("bg1")
    ); //white
    document.documentElement.style.setProperty(
      "--bg-coffee",
      localStorage.getItem("bg2")
    ); //black

    localStorage.setItem("text1", text1);
    localStorage.setItem("text2", text2);
    localStorage.setItem("text3", text3);

    localStorage.setItem("bg1", bg1);
    localStorage.setItem("bg2", bg2);

    // document.documentElement.style.setProperty("text-white", "white");
  };

  // console.log("storage ", localStorage.getItem("text1"));
  return (
    <div className={Style.sideStepper}>
      <StepperItem
        icon={<CiUser className={Style.textWhite} />}
        text={
          <div>
            My name is <span className="text-dark"> shivam Mittal </span> & i am
            a <span className="text-dark">web developer</span>. i am from
            <span className="text-dark"> Rishikesh</span>.
          </div>
        }
      />

      <StepperItem
        icon={<SlGraduation className={Style.textWhite} />}
        text={
          <div>
            I am graduate from
            <span className="text-dark"> P G College Rishikesh </span>. I have
            completed my graduation in
            <span className="text-dark"> 2012</span>.
          </div>
        }
      />

      <StepperItem
        icon={<SiSkillshare className={Style.textWhite} />}
        text={
          <ul className="text-uppercase mt-1 pb-0 ">
            <li> HTML </li>
            <li> CSS </li>
            <li> Bootstrap </li>
            <li> Tailwind Css* </li>
            <li> React Js </li>
            <li> PHP </li>
            <li> Sql </li>
            <li> Laravel* </li>
          </ul>
        }
      />

      <StepperItem
        icon={<TiContacts className={Style.textWhite} />}
        text={
          <div className="d-flex flex-column gap-2">
            <a
              href="tel:+919997077644"
              className={`text-decoration-none d-flex my-auto gap-1 ${Style.anchor}`}
            >
              <RxMobile className="mt-1" />
              <span className="d-block">9997077644</span>
            </a>
            <a
              href="https://wa.me/9997077644?text=Hello Shivam, Are you available?"
              className={`text-decoration-none d-flex my-auto gap-1 ${Style.anchor}`}
            >
              <FaWhatsapp className="mt-1" />
              <span className="d-block">9997077644</span>
            </a>

            <a
              href="mailto:thedevelopershivam@gmail.com"
              className={`text-decoration-none d-flex my-auto gap-1 ${Style.anchor}`}
            >
              <CiLinkedin className="mt-1" />
              <span className="d-block text-lowercase">
                thedevelopershivam@gmail.com
              </span>
            </a>

            <a
              href="linkedin.com/in/shivam-mittal-21578821b"
              className={`text-decoration-none d-flex my-auto gap-1 ${Style.anchor}`}
            >
              <HiOutlineMail className="mt-1" />
              <span className="d-block">Linkedin Profile</span>
            </a>
          </div>
        }
      />

      {/* setting */}
      <StepperItem
        icon={<HiOutlineCog6Tooth className={Style.textWhite} />}
        text={
          <div className="d-flex flex-column gap-2">
            <div className={`h5 text-center fw-bold`}> Color Theme </div>

            <div className="d-flex gap-3">
              {/* Black And White Theme */}
              <div
                className={`${Style.blackNwhite}`}
                // #ee2e31
                onClick={() =>
                  themeHandler(
                    "white",
                    "#222",
                    "rgba(200, 0, 90, 1)",
                    "white",
                    "#222"
                  )
                }
              ></div>

              {/* Orange and coffee */}
              <div
                className={`${Style.coffeeNOrange}`}
                onClick={() =>
                  themeHandler(
                    "#ee751d",
                    "#211d1c",
                    "white",
                    "#ee751d",
                    "#211d1c"
                  )
                }
              ></div>

              {/* Orange and coffee */}
              <div
                className={`${Style.greenNpink}`}
                onClick={() =>
                  themeHandler(
                    "rgba(200, 0, 90, 1)",
                    "#001b06",
                    "white",
                    "rgba(200, 0, 90, 1)",
                    "#001b06"
                  )
                }
              ></div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default Bubbles;
