import Style from "../css/Index.module.css";

export function AboutMe() {
  return (
    <div className={`container-fluid mt-70`}>
      <div className="row">
        <div className="col-12">
          <p className={`${Style.textNavy} h2 text-center`}> About Me </p>
        </div>
      </div>
      <div className={`row  ${Style.aboutMe}`}>
        <div className="col-11 col-sm-9 col-md-5 m-auto">
          <img
            src="assets/images/user.gif"
            alt="Shivam Mittal"
            className="img-fluid"
          />
        </div>
        <div
          className={`col-11 col-sm-9 col-md-5 m-auto position-relative ${Style.aboutMeHeight}`}
        >
          <p className={`${Style.textNavy} h2 text-center`}>
            About My Coding Journey
          </p>

          <p>
            My coding journey began three years ago when I stumbled upon some
            HTML and CSS tutorial videos on YouTube. At that moment, I knew that
            coding was my passion and that I wanted to pursue it as a career. I
            quickly became immersed in the world of web development and began to
            explore various programming languages, frameworks, and libraries.
          </p>
          <p>
            Through dedication and perseverance, I honed my skills and
            eventually became a professional PHP developer. I have worked on
            numerous projects and have constantly pushed myself to learn and
            grow as a developer. Along the way, I have learned to break down
            complex problems into manageable tasks and to find creative
            solutions to challenging issues.
          </p>
          <p>
            Despite the challenges that I have faced along the way, I have never
            lost my passion for coding. It is a field that constantly challenges
            me and allows me to express my creativity in a meaningful way. I am
            proud of my journey as a developer and am excited to see where it
            takes me in the future. With each new project, I am reminded of the
            endless possibilities that coding offers, and I am eager to continue
            exploring and learning in this dynamic field.
          </p>
        </div>
      </div>
    </div>
  );
}
