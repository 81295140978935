import Style from "../css/Index.module.css";
import AnimatedText from "../components/AnimatedTextF";

import Education from "../components/Education";
import { Puzzle } from "../components/Puzzle";
import { AboutMe } from "../components/AboutMe";
import ContactMe from "../components/ContactMe";
import Bubbles from "../components/Bubbles";
import ProjectCard from "../components/ProjectCard";
import Skills from "../components/Skills";
import { Experience } from "../components/Experience";

function Index() {
  return (
    <>
      <div
        className={`${Style.contentWrapper} ${Style.contentCenter} container-fluid`}
      >
        <Bubbles opacity={1} title="Shivam Mittal" />

        <div className="row">
          <div className="col-12 d-flex flex-column text-center justify-content-center align-items-center ">
            <span
              className={`h5 z-inherit ${Style.infoText} ${Style.textWhite}`}
            >
              Say <span className="text-primary display-3">Hi</span> from Shivam
              Mittal. I am a web developer.
            </span>
            <AnimatedText
              animtedText={[
                "Hello! My name is Shivam Mittal.",
                "I am a Web Developer",
                "React Js",
                "HTML",
                "CSS",
                "BOOTSTRAP",
                "TAILWIND CSS*",
                "PHP",
                "Sql",
                "laravel*",
              ]}
            />
          </div>
        </div>
      </div>

      <div className="text-center mt-70">
        <h2 className={Style.textNavy}> My Qualifications </h2>
        <Education />
      </div>

      <div className="text-center mt-70">
        <h2 className={Style.textNavy}> My Experiences </h2>
        <Experience />
      </div>

      <div className="text-center mt-70">
        <h2 className={Style.textNavy}> Skills </h2>
        <Skills />
      </div>

      <AboutMe />
      <Puzzle />
      <div className="text-center mt-70">
        <h2 className={Style.textNavy}> My Previous Work </h2>
        <ProjectCard />
      </div>
      <div className="text-center mt-70">
        <ContactMe />
      </div>
    </>
  );
}

export default Index;
